import cn from 'classnames'
import { ReactNode, useMemo } from 'react'

import { Image, Sizes } from 'ui/image'
import { Icon } from 'ui/icon'

import { ProductDetailsLink } from 'components/product-details-link'
import { StrikedOffPrice } from 'components/striked-off/striked-off-price'
import { PercentDiscoutCallout } from 'components/striked-off'

import {
  MERCHANDISE_LABELS,
  getProductMerchandiseLabel,
  getProductPlaceholderImages,
} from 'page-modules/product/utils'
import { Wishlist } from 'page-modules/product/wishlist'

import useTranslation from 'lib/hooks/useTranslation'
import useDateCurrency from 'lib/hooks/useDateCurrency'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'

import StarIcon from 'brand-assets/illustrations/icons/rating-star.svg'

import s from './styles.module.scss'

export type ProductCardProps = {
  size?: Exclude<Sizes, 'xlarge'>
  data?: Product
  loading?: boolean
  isPinnedCard?: boolean
  indexInList: number
  imageSize?: Sizes
  imageLazyLoadOffset?: string
  disableLazyloadImage?: number[]
  hideWishlist?: boolean
  showDestination?: boolean
  ranking?: string
  callout?: string
  children?: ReactNode
  refetch?: () => void
  onClick?: () => void
}

const SMALL_PRICE_THRESHOLD = 1000000

const ProductCard = ({
  size,
  data,
  loading,
  imageSize,
  imageLazyLoadOffset,
  disableLazyloadImage,
  indexInList,
  isPinnedCard,
  hideWishlist,
  showDestination,
  ranking,
  callout,
  children,
  refetch,
  onClick,
}: ProductCardProps) => {
  const { t } = useTranslation(['common', 'product'])
  const {
    productId = '',
    productUri,
    productName,
    destinationId,
    destinationName,
    currency,
    priceRangeFrom,
    strikeOffDiscountValue,
    strikeOffDiscountType,
    cancellationType,
    rating,
    productTags,
  } = data || {}

  const { isMobileView } = useGlobalContext()

  const { formatNumber } = useDateCurrency()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const showWishlist = useMemo(() => showFeature('wishlist'), [showFeature])

  const showStrikeOffPrice = !!(strikeOffDiscountValue && strikeOffDiscountValue > 0)

  const merchandiseLabelMap = useMemo(() => {
    return {
      [MERCHANDISE_LABELS.TAG_SPECIAL_DEALS]: t('t.deal', { ns: 'common' }),
      [MERCHANDISE_LABELS.TAG_PELAGO_EXCLUSIVE]: t('t.exclusive', { ns: 'common' }),
    }
  }, [t])

  const merchandiseLabel = useMemo(() => {
    const productMerchandiseLabel = getProductMerchandiseLabel(productTags, merchandiseLabelMap)
    return productMerchandiseLabel?.merchandiseLabel
  }, [productTags, merchandiseLabelMap])

  const imageSrc = useMemo(() => {
    if (data?.thumbnail?.sizes) {
      return data?.thumbnail
    } else if (data?.mediaData?.[0]?.sizes) {
      return data?.mediaData?.[0]
    } else {
      return { ...data?.thumbnail, ...getProductPlaceholderImages() }
    }
  }, [data?.mediaData, data?.thumbnail])

  const displayDestinationName = useMemo(() => {
    if (!showDestination || !destinationId) return null

    return destinationName ?? destinationId
  }, [destinationId, destinationName, showDestination])

  const cardSize: ProductCardProps['size'] = size ? size : isMobileView ? 'small' : 'medium'

  if (loading) {
    return (
      <div className={cn(s.productCard, s.loading, s[cardSize])}>
        <div className={s.imageContainer} />
        <div className={s.body}>
          <div className={s.destinationName} />
          <div className={s.productName} />
          <div className={s.productName} />
          {size === 'small' && <div className={s.productName} />}
          <div className={s.footer}>
            <div className={s.label} />
          </div>
        </div>
      </div>
    )
  }

  if (!data) {
    return null
  }

  return (
    <ProductDetailsLink
      productId={productId}
      productUri={productUri}
      destinationId={destinationId}
      underline={false}
      className={s.productCardLink}
    >
      <div className={cn(s.productCard, s[cardSize], { [s.pinned]: isPinnedCard })} onClick={onClick}>
        <div className={s.imageContainer}>
          <Image
            size={imageSize || 'medium'}
            layout="fill"
            src={imageSrc}
            alt={productName}
            className={s.image}
            lazyLoadOffset={imageLazyLoadOffset}
            lazyLoad={!disableLazyloadImage ? true : disableLazyloadImage?.indexOf?.(indexInList) === -1}
            lazyLoadHiddenPoint
          >
            {!hideWishlist && showWishlist && (
              <div className={s.wishlistBtn}>
                <Wishlist
                  productId={productId}
                  destinationId={destinationId}
                  product={data}
                  refetchProduct={refetch}
                  variant="static"
                />
              </div>
            )}
            {(callout || merchandiseLabel) && <div className={s.callout}>{callout || merchandiseLabel}</div>}
            {!!rating && (
              <div className={s.rating}>
                <span className={s.ratingIcon}>
                  <StarIcon />
                </span>
                {rating.toFixed(1)}
              </div>
            )}
          </Image>
        </div>
        <div className={s.body}>
          <div className={s.bodyHeader}>
            {displayDestinationName && <span className={s.destinationName}>{displayDestinationName}</span>}
            {ranking && (
              <span className={s.ranking}>
                <Icon name="hash" size="small" /> {ranking}
              </span>
            )}
            <h3 className={s.productName}>{productName}</h3>
          </div>
          <div className={s.footer}>
            <div className={s.priceAndLabel}>
              <div>
                <div className={s.fromPriceValue}>
                  <span>{t('t.from', { ns: 'common' })}</span>
                  {showStrikeOffPrice && (
                    <span>
                      <StrikedOffPrice
                        currency={currency}
                        discount={strikeOffDiscountValue}
                        discountType={strikeOffDiscountType}
                        total={priceRangeFrom}
                      />
                    </span>
                  )}
                </div>
                <span
                  className={cn(s.priceValue, {
                    [s.smallText]: showStrikeOffPrice && (priceRangeFrom || 0) >= SMALL_PRICE_THRESHOLD,
                  })}
                >
                  {`${currency} ${formatNumber(priceRangeFrom)}`}
                  {showStrikeOffPrice && (
                    <PercentDiscoutCallout
                      discount={strikeOffDiscountValue}
                      discountType={strikeOffDiscountType}
                      calloutSize="mini"
                    />
                  )}
                </span>
              </div>
              {cancellationType === 'ALLOW_CANCELLATION' && (
                <span className={s.label}>{t('freeCancelation', { ns: 'product' })}</span>
              )}
            </div>
          </div>
        </div>
        {children}
      </div>
    </ProductDetailsLink>
  )
}

export { ProductCard }
